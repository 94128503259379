  <template>
  <div>
    <el-dialog :title="text+'讲义'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               :validate-on-rule-change="false"
               ref="formRefs"
               v-if="dialogVisible"
               label-width="80px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="讲义名称"
                          prop="lecture_name">
              <el-input clearable
                        placeholder="请输入"
                        v-model="form.lecture_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="讲义类别"
                          prop="lecture_category_id">
              <el-select v-model="form.lecture_category_id"
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in categoryList"
                           :key="item.lecture_category_id"
                           :label="item.lecture_category_name"
                           :value="item.lecture_category_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="上传文件"
                          prop="filename">
              <el-button type="primary"
                         v-if="!form.filename"
                         @click="checkFile">
                选择文件
              </el-button>
              <el-tag closable
                      v-else
                      @close="clear">
                {{form.filename}}
              </el-tag>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="讲义描述">
              <el-input clearable
                        placeholder="请输入"
                        v-model="form.lecture_description"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="排序">
              <el-input-number clearable
                               placeholder="请输入"
                               v-model="form.sort"
                               :precision='0'
                               :min="0"
                               style="width:100%;"
                               controls-position="right" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="题目编码">
              <el-input clearable
                        placeholder="请输入"
                        v-model="form.lecture_questions"
                        style="width:100%" />
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  class="hint">
            说明：多道题目是以半角分号分隔的题目编码
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      text: '新增',
      form: {},
      file: null,
      rules: {
        lecture_name: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        lecture_category_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        filename: [
          { required: true, message: '请选择', trigger: 'change' },
        ]
      },
      treeList: [],
      categoryList: []
    }
  },
  methods: {
    clear () {
      this.file = null
      this.$set(this.form, 'filename', '')
      console.log(this.form.filename)
    },
    handleClose () {
      this.form = {}
      this.$refs.formRefs.resetFields()
      this.dialogVisible = false
    },
    handleSave () {
      this.$refs.formRefs.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))

        let formdata = new FormData();
        if (form.filename) {
          form.filename = 'pdf'
          formdata.append('pdf', this.file)
        } else {
          delete form.filename
        }

        for (const key in form) {
          formdata.append(key, form[key])
        }
        formdata.append('sign', this.$getMd5Form(form))
        let url = this.text == '新增' ? '/api/v1/lecture/create' : '/api/v1/lecture/edit'

        this.$http({
          url,
          method: 'POST',
          data: formdata
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.$parent.refresh()
          this.handleClose()
        })
      })
    },
    checkFile () {
      this.$get_file({
        limit: 50,
        fileType: '.pdf'
      }).then(res => {
        this.form.filename = res.name
        this.file = res
      })
    },
    getCategoryList () {
      this.$http({
        url: '/api/v1/lecture/category',
        method: 'get',
        params: {
          page: 1,
          limit: 100000
        }
      }).then(res => {
        this.categoryList = res.data.list
      })
    },
    delFileRole () {
      this.rules.filename = []
    },
    addFileRole () {
      this.rules.filename = [
        { required: true, message: '请选择', trigger: 'change' },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.tree_warp {
  height: 350px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.hint {
  font-size: 14px;
}
::v-deep .el-input-number.is-controls-right .el-input__inner {
  text-align: left;
}
</style>